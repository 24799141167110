const entities = {
  '&lt;': '<',
  '&gt;': '>',
  '&amp;': '&',
  // eslint-disable-next-line quotes
  '&apos;': "'",
  '&quot;': '"',
}

const keys = Object.keys(entities)
const reggex = new RegExp(`(${keys.join('|')})`, 'g')

export default function decodeHTMLEntities(text) {
  return text?.replace(reggex, match => entities[match])
}
